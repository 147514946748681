import * as React from "react";
import { container, subtitle, title3, headerContainer, category, pageLink, linkContainer } from "./Sitemap.module.scss";
import Col from "react-bootstrap/Col";
import { Link, navigate } from "gatsby";

export const Container = (props: {children: JSX.Element}) => {
  return (<div className={container}>{props.children}</div>);
};

export const Subtitle = (props: {children: JSX.Element}) => {
  return (<div className={subtitle}>{props.children}</div>);
};

export const Title3 = (props: {children: JSX.Element}) => {
  return (<div className={title3}>{props.children}</div>);
};

export const HeaderContainer = (props: {children: JSX.Element}) => {
  return (<div className={headerContainer}>{props.children}</div>);
};

export const Category = (props: {to: string, children: JSX.Element}) => {
  return (<div className={category} onClick={() => navigate(props.to)}>{props.children}</div>);
};

export const PageLink = (props: {to: string, children: JSX.Element}) => {
  return (<Link to={props.to} className={pageLink}>{props.children}</Link>);
};


const transformData = (rawData: any) => {
  const result = [];
  const maxElements = 6;  // max elemtents per column
  let count = 0;
  let pushIndex = 0;
  for (let i = 0; i < rawData.length; i++) {
    if (count < maxElements) {
      if (result[pushIndex]) {
        result[pushIndex] = [...result[pushIndex], rawData[i]];
      } else {
        result[pushIndex] = [rawData[i]];
      }
      count = count + (rawData[i].field_sitemap_links?.length || 0) + 1;
    } else {
      result[pushIndex + 1] = [rawData[i]];
      pushIndex++;
      count = (rawData[i].field_sitemap_links?.length || 0) + 1;
    }
  }
  return result;
};

export const renderSiteMapLinks = (siteMapData:any) => {
  const transformedData = transformData(siteMapData);
  return transformedData.map((colElms, index) => {
    return (
      <Col lg={4} key={index}>
        {
          colElms.map((section) => {
            return (<>
              <div>
                <Category to={section.field_sitemap_title.url}>
                  {section.field_sitemap_title.title}
                </Category>
              </div>
              <ul className={`${linkContainer} list-unstyled`} key={section.field_sitemap_title.title}>
                {section.field_sitemap_links?.map(({ url, title }: {url: string, title: JSX.Element}) => {
                  return (
                    <li key={title}>
                      <PageLink to={url}>{title}</PageLink>
                    </li>
                  );
                })}
              </ul>
            </>);
          })
        }
      </Col>
    );
  });
};
